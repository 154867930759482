import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Spinner from "../Spinner";
import { MdOutlinePassword } from 'react-icons/md';
import { Button, FormControl, IconButton, MenuItem, Select, TextField } from "@mui/material";
import moment from 'moment';
import 'moment/locale/es';
import { uriSerialized } from "../../utils/uriSerialized";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaEnvelopeCircleCheck } from "react-icons/fa6";
import { verificarCodigo } from "../../store/features/slices/cuentaSlice";
import { message, validarEmail } from "../../utils/Funciones";
import { IoReloadSharp } from "react-icons/io5";
import { FaKey } from "react-icons/fa";
import { SET_TO_DEFAULT_RESPONSE } from "../../store/features/slices/cuentaSlice";
import { LazyLoadImage } from "react-lazy-load-image-component";

const VerifyCode = ({ }) => {
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const { isMutation, verifyCodeEmail } = useSelector((state) => state.cuentaReducer);
   const { configuration } = useSelector((state) => state.userReducer);
   const [state, setState] = useState({
      type: 'netflix',
      email: '',
      server: 'server1'
   })

   const location = useLocation();
   const [isLoading, setIsLoading] = useState(false);

   useEffect(() => {
      const handleStartLoading = () => setIsLoading(true);
      const handleStopLoading = () => setIsLoading(false);

      handleStartLoading();

      const timer = setTimeout(() => {
         handleStopLoading();
      }, 500);

      return () => clearTimeout(timer);
   }, [location]);

   const SubmitHandleVerifyCode = (e) => {
      e.preventDefault()

      if (!validarEmail(state.email)) {
         message('el correo tiene un formato inválido', 'error', 5)
      } else {
         const queryOptions = {
            email: state.email,
            server: state.server,
            type: state.type
         }
         const query = queryOptions ? "?" + uriSerialized(queryOptions) : ""
         dispatch(verificarCodigo(query))
      }
   }

   const extractTableFromHTML = (html) => {

      const matchHtml = state.type === 'netflix' ?
         html.match(/<table[^>]*class="inner-button border-false"[^>]*>[\s\S]*?<\/table>/) :
         state.type === 'disney' ? 
         html.match(/\+[\s\S]*?>[\s]*?(\d{6})[\s]*?</)[1] :
            html.match(/<span[^>]*class="rio-text rio-text-9"[^>]*>[\s\S]*?<\/span>/);

      const tableMatch = state.type === 'netflix' ? matchHtml : [matchHtml];
      return tableMatch ? tableMatch[0] : '';
   };

   useEffect(() => {
      if (isMutation.success) {
         SET_TO_DEFAULT_RESPONSE()
         const linkNetflix = document.querySelector('a.h5');
         if (linkNetflix) {
            linkNetflix.click();
         }
      }
   }, [isMutation.success])

   return (
      <div className="w-full min-h-screen bg-lightPrimary/50 dark:bg-darkPrimary/50">
         <div className="w-full md:w-10/12 m-auto p-4 relative">
            {/* HEADER PAGES */}
            <div className="sticky p-4 flex flex-wrap text-brand-900 dark:text-brand-100 uppercase w-full font-semibold bg-gradient-to-r from-white dark:from-brand-900 backdrop-blur-lg z-30 rounded-xl">
               <div className="w-full h-14 text-lg md:text-2xl flex items-center">
                  <div className="flex items-center w-full">
                     <MdOutlinePassword size={65} style={{ marginRight: 10 }} />
                     Verificar códigos
                  </div>
               </div>
            </div>

            <div className="sticky p-4 flex flex-wrap text-brand-900 dark:text-brand-100 uppercase w-full font-semibold z-30 rounded-xl">
               <div className="w-full flex items-center justify-center cursor-pointer">
                  {
                     <Link to="/" className="">
                        <LazyLoadImage
                           className="h-[120px] md:h-[155px] lg:h-[155px]"
                           alt={'No image logo'}
                           src={`/images/logos/${configuration?.logo}`}
                        />
                     </Link>
                  }
               </div>
            </div>

            <div className="w-full mt-5 md:w-12/12 flex justify-center">
               <form className="relative w-full md:w-4/12 flex flex-wrap" onSubmit={SubmitHandleVerifyCode}>

                  <div className="w-full md:w-12/12 p-3">
                     <label for="first_name" class="block mb-2 text-sm font-semibold text-brand-900 dark:text-white">Seleccionar servidor</label>
                     <FormControl fullWidth>
                        <Select
                           required
                           name="server"
                           variant="outlined"
                           value={state.server}
                           onChange={(e) => setState({ ...state, server: e.target.value })}
                        >
                           <MenuItem value={'server1'} key={'server1'} className="flex items-center">
                              <LazyLoadImage
                                 src='/images/helpers/server.webp'
                                 className="mr-2 rounded-lg object-cover min-w-[35px] max-w-[35px] min-h-[35px] max-h-[35px]" />
                              Server 1
                           </MenuItem>

                           <MenuItem value={'server2'} key={'server2'} className="flex items-center">
                              <LazyLoadImage
                                 src='/images/helpers/server.webp'
                                 className="mr-2 rounded-lg object-cover min-w-[35px] max-w-[35px] min-h-[35px] max-h-[35px]" />
                              Server 2
                           </MenuItem>
                        </Select>
                     </FormControl>
                  </div>

                  <div className="w-full md:w-12/12 p-3">
                     <label for="first_name" class="block mb-2 text-sm font-semibold text-brand-900 dark:text-white">Seleccionar plataforma</label>
                     <FormControl fullWidth>
                        <Select
                           required
                           name="type"
                           variant="outlined"
                           value={state.type}
                           onChange={(e) => setState({ ...state, type: e.target.value })}
                        >
                           <MenuItem value={'netflix'} key={'netflix'} className="flex items-center">
                              <LazyLoadImage
                                 src='/images/helpers/netflix.webp'
                                 className="mr-2 rounded-full object-cover min-w-[35px] max-w-[35px] min-h-[35px] max-h-[35px]" />
                              Netflix
                           </MenuItem>

                           <MenuItem value={'disney'} key={'disney'} className="flex items-center">
                              <LazyLoadImage
                                 src='/images/helpers/disney.webp'
                                 className="mr-2 rounded-full object-cover min-w-[35px] max-w-[35px] min-h-[35px] max-h-[35px]" />
                              Disney
                           </MenuItem>

                           <MenuItem value={'primevideo'} key={'primevideo'} className="flex items-center">
                              <LazyLoadImage
                                 src='/images/helpers/primevideo.webp'
                                 className="mr-2 rounded-full object-cover min-w-[35px] max-w-[35px] min-h-[35px] max-h-[35px]" />
                              Prime Video
                           </MenuItem>
                        </Select>
                     </FormControl>
                  </div>

                  <div className="w-full md:w-12/12 p-3">
                     <label for="first_name" class="block mb-2 text-sm font-semibold text-brand-900 dark:text-white">Ingresar el correo {state.type && `de ${state.type}`}</label>
                     <input
                        required
                        name="email"
                        className="custom-style-input"
                        sid="outlined-basic" variant="outlined"
                        autoComplete="off"
                        type="email"
                        value={state.email}
                        onChange={(e) => setState({ ...state, email: e.target.value })}
                     />
                  </div>
                  <div className="w-full p-3 mt-1">
                     <Button
                        sx={{
                           "&.Mui-disabled": {
                              background: "#525252",
                              color: "#a3a3a3"
                           }
                        }}
                        disabled={isMutation.loading || isLoading ? true : false}
                        type="submit" style={{ width: '100%', height: 65, marginLeft: 'auto', marginRight: 'auto' }}
                        size='large' variant="contained" color={`${state.type === 'netflix' ? 'error' : 'primary'}`} startIcon={isMutation.loading ? <Spinner w={'35px'} h={'35px'} /> : <FaEnvelopeCircleCheck size={24} />}>{isMutation.loading ? 'Verificando...' : 'Consultar código'}
                     </Button>
                  </div>
               </form>
            </div>
            {
               verifyCodeEmail &&
               <div className={`w-full flex flex-wrap justify-center mt-3 bg-white dark:bg-brand-900 p-5 ${state.type === 'netflix' ? 'hidden' : ''}`}>
                  <ul className="">
                     <div className="text-center font-semibold text-2xl mb-5 text-brand-950 dark:text-brand-50">{verifyCodeEmail.subject}</div>
                     <div className="flex items-center mb-5">
                        <LazyLoadImage
                           src={`${state.type === 'netflix' ? '/images/helpers/netflix.webp' : state.type === 'disney' ? '/images/helpers/disney.webp' : '/images/helpers/primevideo.webp'}`}
                           className="mr-2 rounded-full object-cover min-w-[65px] max-w-[65px] min-h-[65px] max-h-[65px]" />
                        <div className="ml-2 text-brand-950 dark:text-brand-50">
                           {state.email}
                        </div>
                     </div>
                     <div className="text-center text-4xl text-brand-950 dark:text-brand-50 font-bold" dangerouslySetInnerHTML={{ __html: extractTableFromHTML(verifyCodeEmail.body) }} />
                     <div className="text-center mt-5 text-brand-950 dark:text-brand-50">código recibido {moment(verifyCodeEmail.date).fromNow()}</div>
                  </ul>
               </div>
            }
         </div>
      </div >
   )
}
export default VerifyCode;