import { Pagination, PaginationItem, Stack, Tooltip, IconButton, Button, MenuItem, Menu } from '@mui/material';
import React, { useState, useEffect, useMemo } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { FaCaretDown, FaClipboard, FaEllipsisV, FaPencilAlt, FaPlus, FaShippingFast, FaShoppingBag, FaTrashAlt, FaUserCheck } from 'react-icons/fa';
import { IoAlarm, IoBagCheck, IoBan, IoCheckbox, IoCloseSharp, IoDesktopSharp, IoEllipsisVertical, IoEye, IoEyeOff, IoFingerPrint, IoLockClosed, IoLockOpen, IoMenu, IoPerson, IoReload, IoReloadCircleSharp, IoRocket, IoSave, IoThumbsDownSharp, IoTime } from 'react-icons/io5';
import SpinnerData from '../../components/SpinnerData';
import { CalcularExpiracion, calcularReembolsoCompleto, currencyFormat, message, setUnixToTimeFormat, validarEmail } from '../../utils/Funciones';
import draftToHtml from "draftjs-to-html";
import Swal from "sweetalert2";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import debounce from 'lodash.debounce';
import Wrapper from './Wrapper';
import moment from 'moment';
import { MdAlternateEmail, MdAutorenew, MdAvTimer, MdDelete, MdMail, MdOutgoingMail, MdOutlineTimerOff, MdPersonPin, MdTimer } from 'react-icons/md';
import Spinner from '../../components/Spinner';
import Spinner2 from '../../components/Spinner2';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import { useDispatch, useSelector } from 'react-redux';
import { uriSerialized } from '../../utils/uriSerialized';
import { SET_TO_DEFAULT_RESPONSE, getBypage, contador as getContador, updateExpirationService, completarPedido, completarPedidoConEmail as onCompletarPedidoWhitEmail, del, rejectedOrLockVenta, suspendedById, transferirPedido, reembolsarPedido } from '../../store/features/slices/ventaSlice';
import { FiSearch } from 'react-icons/fi';
import { Input, InputGroup, InputLeftElement, InputRightElement } from '@chakra-ui/react';
import Modal from '../../components/modal/Modal';
import { FaBan, FaCoins, FaEnvelope, FaEye, FaHandHoldingDollar, FaLock, FaLockOpen, FaMobileScreen, FaRegCalendarPlus, FaRegUser, FaRocket, FaScreenpal, FaShopify, FaSquareCheck, FaUserClock, FaUserGroup, FaUserTag, FaWhatsapp } from 'react-icons/fa6';
import { sendEmailWhitInformationService, SET_TO_DEFAULT_RESPONSE as SET_TO_DEFAULT_RESPONSE_NOTIFICATION } from '../../store/features/slices/notificationSlice';
import Socket, { authSocket, logoutSocket } from '../../utils/Socket';
import SpinnerButtom from '../../components/SpinnerButtom';
import Dropdown from '../../components/dropdown';

const StreamingOrders = ({ }) => {
  const dispatch = useDispatch();

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  var htmlData = useMemo(
    () => draftToHtml(convertToRaw(editorState.getCurrentContent())),
    [editorState]
  );

  const [anchorEls, setAnchorEls] = useState({}); // Estado para múltiples menús

  const handleClick = (event, index) => {
    setAnchorEls((prev) => ({ ...prev, [index]: event.currentTarget }));
  };

  const handleClose = (index) => {
    setAnchorEls((prev) => ({ ...prev, [index]: null }));
  };

  const [curTime, SetCurTime] = useState(null)
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [pedidosByState, SetPedidosByState] = useState('')
  const [infopedido, setInfopedido] = useState('')
  const [limit, setLimit] = useState(30);
  const [transferOrder, SetTransferOrder] = useState({
    open: false,
    _id: "",
    userTarget: ""
  })

  const [reembolsarOrder, SetReembolsarOrder] = useState({
    open: false,
    _id: "",
    amount: ""
  })

  const [verdetalles, SetVerDetalles] = useState({
    open: false,
    categoryname: "",
    categorylogo: "",
    categoryrenovable: false,
    correo: "",
    passwordcuenta: "",
    data: [],
    copied: false
  })

  const [completarPedidoConEmail, setCompletarPedidoConEmail] = useState({
    open: false,
    _id: "",
    correo: "",
    passwordcorreo: "",
    passwordcuenta: "",
    verpasswordcorreo: false,
    verpasswordcuenta: false
  })

  const [changeExpirationTime, setChangeExpirationTime] = useState({
    open: false,
    _id: "",
    newexpiration: ""
  })

  const [questionrejected, SetQuestionRejected] = useState({
    open: false,
    _id: "",
    actiontype: "",
    message: ""
  })

  const {
    ventasByPage,
    isMutation,
    contador,
    totalFilter,
    total,
    totalPages
  } = useSelector((state) => state.ventaReducer);


  const {
    isMutation: isMutationNotification
  } = useSelector((state) => state.notificationReducer);

  useEffect(() => {
    if (isMutation.success) {
      message(isMutation.message, 'success', 5)
      dispatch(getContador())
      dispatch(SET_TO_DEFAULT_RESPONSE())
    }
    const queryOptions = {
      page: currentPage,
      limit: limit,
      search: searchTerm.trim(),
    }
    const query = queryOptions ? "?" + uriSerialized(queryOptions) : ""
    dispatch(getBypage(query))
  }, [dispatch, searchTerm, currentPage, limit, isMutation.success]);

  useEffect(() => {
    if (isMutationNotification.success) {
      message(isMutationNotification.message, 'success', 5)
      dispatch(SET_TO_DEFAULT_RESPONSE_NOTIFICATION())
    }
  }, [dispatch, isMutationNotification.success]);

  useEffect(() => {
    if (isMutation.updated) {
      message(isMutation.message, 'success', 5)
      if (isMutation.extra?.affectSubCategory) {
        Socket.emit('uSubCategory', { newData: isMutation.extra.subcategory })
      }
      completarPedidoConEmail.open && setCompletarPedidoConEmail({ ...completarPedidoConEmail, open: false, _id: "", passwordcorreo: "", passwordcuenta: "", correo: "" })
      changeExpirationTime.open && setChangeExpirationTime({ ...changeExpirationTime, open: false, _id: "", newexpiration: "" })
      questionrejected.open && SetQuestionRejected({ ...questionrejected, open: false, _id: '', actiontype: '', message: '' })
      transferOrder.open && SetTransferOrder({ ...transferOrder, open: false, _id: '', userTarget: '' })
      reembolsarOrder.open && SetReembolsarOrder({ ...reembolsarOrder, open: false, _id: '', amount: '' })
      dispatch(SET_TO_DEFAULT_RESPONSE())
    }
  }, [isMutation.updated])

  useEffect(() => {
    dispatch(getContador())
  }, []);

  const deleteVenta = (id) => {
    alertatresbotones('Eliminar pedido', 'Click en el boton borrar este pedido para eliminar y click borrar todo para borrar todos los pedidos bloqueados y rechazados.', 'question', (res_alert) => {
      if (res_alert === true) {
        dispatch(del({ id, type: 'uno' }));
      } else if (res_alert === false) {
        dispatch(del({ id, type: 'todos' }));
      }
    })
  }

  useEffect(() => {
    const interval = setInterval(async () => {
      SetCurTime(new Date().toLocaleString());
    }, 1000);
    return () => clearInterval(interval);
  }, [])

  const handleLockOrRejectedVenta = (id, actiontype, state, isExpired) => {

    if (actiontype === 'locked') {
      if (state === 'complete' && isExpired !== 'Caducado') {
        alertaConfirmar('Bloquear pedido - No expirado', 'Este pedido aún no ah expirado, deseas forzar el bloqueo ?', 'question', (res_alert) => {
          if (res_alert === true) {
            dispatch(rejectedOrLockVenta({ _id: id, actiontype: actiontype, message: questionrejected.message, force: true, from: 'orders' }));
          }
        })
      } else {
        alertaConfirmar('Bloquear pedido', 'Al bloquear este pedido el usuario ya no podrá renovarlo ni tener ningún acceso.', 'warning', (res_alert) => {
          if (res_alert === true) {
            dispatch(rejectedOrLockVenta({ _id: id, actiontype: actiontype, message: questionrejected.message, force: false, from: 'orders' }));
          }
        })
      }
    } else if (actiontype === 'rejected') {
      if (questionrejected.message.trim().length > 0) {
        alertaConfirmar('Rechazar Pedido', 'El usuario recibira una notificación sobre el motivo de rechazo de su pedido.', 'warning', (res_alert) => {
          if (res_alert === true) {
            dispatch(rejectedOrLockVenta({ _id: id, actiontype: actiontype, message: questionrejected.message, force: false, from: 'orders' }));
          }
        })
      } else {
        message('Debes ingresar un motivo por el cual estás rechazando este pedido', 'error', 4)
      }
    }
  }

  const handleSuspended = (venta) => {
    if (venta.suspended) {
      alertaConfirmar('REANUDAR PEDIDO', 'estás seguro que quieres reanudar este pedido? el usuario recibirá automaticamente las crendeciales actuales.', 'warning', (res_alert) => {
        if (res_alert === true) {
          dispatch(suspendedById({ _id: venta._id, actiontype: 'unsuspend', from: 'orders' }));
        }
      })
    } else {
      alertaConfirmar('SUSPENDER PEDIDO', 'estás seguro que quieres suspender este pedido? el usuario no recibirá las credenciales de este pedido.', 'warning', (res_alert) => {
        if (res_alert === true) {
          dispatch(suspendedById({ _id: venta._id, actiontype: 'suspend', from: 'orders' }));
        }
      })
    }
  }

  const tranferirPedido = (_id) => {
    SetTransferOrder({ ...transferOrder, open: true, _id: _id })
  }

  const refundOrder = (_id, months, amount, created) => {
    function diasExactos(fecha, hora = 4, minutos = 0) {
      const fechaObj = new Date(fecha);

      if (isNaN(fechaObj)) {
        throw new Error('Fecha inválida');
      }

      // Clonar fecha y establecer hora específica
      const fechaHoraEspecifica = new Date(fechaObj);
      fechaHoraEspecifica.setHours(hora, minutos, 0, 0);

      // Calcular diferencia
      const diferenciaMs = Date.now() - fechaHoraEspecifica.getTime();
      const dias = (diferenciaMs / 86400000).toFixed(2); // 86400000 = 1 día en ms

      return {
        dias: parseFloat(dias),
        horas: parseFloat((dias * 24).toFixed(2)),
        fechaBase: fechaHoraEspecifica.toISOString()
      };
    }
    let days = diasExactos(created)
    let refundamount = calcularReembolsoCompleto(Number(amount), months * 30, Number(days.dias))
    SetReembolsarOrder({ ...reembolsarOrder, open: true, _id: _id, amount: Number(refundamount) })
  }

  const handleOpenDetalles = (venta) => {

    SetVerDetalles({
      ...verdetalles,
      ['open']: !verdetalles.open,
      ['data']: venta,
      ['categoryname']: venta.subcategory.name,
      ['categorylogo']: venta.subcategory.logo,
      categoryrenovable: venta.subcategory.renovable,
      ['correo']: venta.correo.correo,
      ['passwordcuenta']: venta.correo.passwordcuenta
    })

    if (venta) {
      var InformacionPedido =
        `*INFORMACIÓN DE LA CUENTA* 
*Servicio :* ${venta.subcategory.name}
*Correo :* ${venta.correo.correo}
*Contraseña :* ${venta.correo.passwordcuenta}
*Tipo de servicio :* ${venta.type}
*Expiración :* ${moment(venta.expirationdate).format("dddd, DD [de] MMMM [de] YYYY")}
${venta.isprofileblocked ?
          `*INFORMACIÓN DE PERFILES* 
*Nombre :* ${venta.dataperfiles[0].name}
*PIN :* ${venta.dataperfiles[0].password}` : ''}`

      setInfopedido(InformacionPedido)
    }
  }

  const onCompletarPedidoConEmail = (e) => {
    e.preventDefault();
    if (completarPedidoConEmail.correo.trim().length < 5) {
      message('correo es requerido', 'error', 5)
    } else if (completarPedidoConEmail.passwordcuenta.trim().length < 5) {
      message('paassword de la cuenta es requerido', 'error', 5)
    } else {
      dispatch(onCompletarPedidoWhitEmail({
        _id: completarPedidoConEmail._id,
        correo: completarPedidoConEmail.correo,
        passwordcorreo: completarPedidoConEmail.passwordcorreo,
        passwordcuenta: completarPedidoConEmail.passwordcuenta
      }))
    }
  }

  const HandleCompletarPedido = (idVenta, categoryId, subcategoryId, userId, maxscreems, type, totalmonths, totalamount, discountuser, CategoryName, isprofileblocked, contactoInfo, policy, requiredemail) => {
    if (requiredemail) {
      setCompletarPedidoConEmail({ ...completarPedidoConEmail, open: true, _id: idVenta, correo: contactoInfo?.email })
    } else {
      const rawContent = policy;
      const contentState = convertFromRaw(JSON.parse(rawContent));
      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState)
      const PedidoData = {
        idVenta: idVenta,
        categoryId: categoryId,
        subcategoryId: subcategoryId,
        userId: userId,
        selectedscreems: type,
        maxscreems: maxscreems,
        selectedmonths: totalmonths,
        totalamount: totalamount,
        discountuser: discountuser,
        name: CategoryName,
        tipoentrega: true,
        isprofileblocked: isprofileblocked,
        contactoInfo,
        actionTypeAdmin: true,
        htmlData: htmlData
      }
      dispatch(completarPedido(PedidoData))
    }
  }

  const handleSendEmailWhitInformationService = (id, policy) => {
    const rawContent = policy;
    const contentState = convertFromRaw(JSON.parse(rawContent));
    const editorState = EditorState.createWithContent(contentState);
    setEditorState(editorState)
    alertaConfirmar('Reenviar pedido', 'Estas seguro que quieres reenviar el pedido ?', 'question', (res_alert) => {
      if (res_alert === true) {
        dispatch(sendEmailWhitInformationService({ id, htmlData }))
      }
    })
  }

  const onCopy = (text) => {
    SetVerDetalles({ ...verdetalles, copied: true });
    message(text, 'success', 2)
  }

  const handleChangePage = (e, p) => {
    setCurrentPage(p);
  };

  const changeHandleSearch = (event) => {
    SetPedidosByState('')
    setSearchTerm(event.target.value)
    setCurrentPage(1);
  };

  const debouncedChangeHandler = useMemo(() => debounce(changeHandleSearch, 500), []);

  const alertatresbotones = (titulo, texto, icon, callback) => {
    Swal.fire({
      title: titulo,
      html: texto,
      icon: icon,
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Borrar este pedido',
      denyButtonText: `Borrar todo`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        callback(true)
      } else if (result.isDenied) {
        callback(false)
      }
    })
  }

  const alertaConfirmar = (titulo, texto, icon, callback) => {
    Swal.fire({
      title: titulo,
      html: texto,
      icon: icon,
      showCancelButton: true,
      confirmButtonText: 'Si',
      denyButtonText: 'No',
      cancelButtonText: 'Cancelar',

      confirmButtonColor: '#2C3E50',
      cancelButtonColor: '#CB4335'
    }).then((result) => {
      if (result.isConfirmed) {
        callback(result.isConfirmed)
      }
    })
  }

  const HandleChangeFilterPedidos = (state) => {
    setSearchTerm(state)
    SetPedidosByState(state)
    setCurrentPage(1);
  }

  const openHandleChangeExpireTime = (currentexpiration, id) => {
    setChangeExpirationTime({ ...changeExpirationTime, _id: id, newexpiration: moment(currentexpiration).format("yyyy-MM-DDTHH:mm"), open: true })
  }

  const onSendChangeExpirationDate = () => {
    dispatch(updateExpirationService({ id: changeExpirationTime._id, newexpiration: changeExpirationTime.newexpiration }))
  }

  const onSendTransferOrder = () => {
    if (transferOrder.userTarget.trim().length < 1) {
      message('correo es requerido', 'error', 3)
    } else {
      dispatch(transferirPedido({ _id: transferOrder._id, userTarget: transferOrder.userTarget }))
    }
  }

  const onSendRefundOrder = () => {
    if (isNaN(Number(reembolsarOrder.amount)) || Number(reembolsarOrder.amount) <= 0) {
      message('monto inválido', 'error', 3)
    } else {
      dispatch(reembolsarPedido({ _id: reembolsarOrder._id, amount: reembolsarOrder.amount }))
    }
  }

  const onChangeSearchTermClear = e => {
    setSearchTerm("");
    HandleChangeFilterPedidos("")
    document.getElementById("search").value = "";
  };

  return (

    <Wrapper>

      <div className="fixed ml-7 md:ml-0 pl-3 md:pl-3 lg:pl-3 h-8 top-2 md:top-11 z-30 flex items-center w-full md:w-8/12 text-brand-900 dark:text-brand-100 text-[1.2rem] md:text-[1.8rem] lg:text-[2rem] font-semibold">
        Pedidos streaming
        <div class="flex items-center justify-center px-3 ml-3 text-xl font-medium bg-brand-300 dark:bg-brand-800 text-brand-800 dark:text-brand-200 rounded-full">
          {!contador.loading && contador.total}
        </div>
      </div>
      {/* HEADER PAGES */}
      <div className="fixed mt-8 p-4 flex items-center text-brand-900 dark:text-brand-100 uppercase w-[calc(100%-1.50rem)] md:w-[calc(100%-1.50rem)] lg:w-[calc(100%-26.50rem)] font-semibold bg-white dark:bg-brand-900 backdrop-blur-lg z-20 rounded-b-xl">
        <div className="!w-9/12">
          <InputGroup className="w-full md:w-[450px] md:max-w-[450px]">
            <InputLeftElement pointerEvents="none" className="!mt-[14px] !ml-2">
              <FiSearch size={24} className="text-brand-900 dark:text-brand-100" />
            </InputLeftElement>
            <Input size='lg' autoFocus id="search" variant='flushed' onChange={debouncedChangeHandler} type="text" placeholder={`Buscar pedidos streaming...`} className="flex w-full md:w-[450px] md:max-w-[450px] !h-14 !pl-10 !items-center !justify-center !border-2 rounded-xl !bg-brand-200 
            dark:!bg-brand-950 !text-sm focus:!ring-lightPrimary dark:focus:!ring-darkPrimary focus:!border-lightPrimary dark:focus:!border-darkPrimary 
            !outline-none !border-brand-300 dark:!border-brand-800 !text-brand-900 dark:!text-white !relative !placeholder-brand-500" />
            <InputRightElement className="!mt-[8px] !mr-1">
              <IconButton onClick={onChangeSearchTermClear} className={`mr-2 ${searchTerm.trim().length > 0 ? 'scale-100' : 'scale-0'} transition-all duration-500 text-brand-600 dark:text-brand-300`}>
                <IoCloseSharp size={20} className="text-brand-900 dark:text-brand-100" />
              </IconButton>
            </InputRightElement>
          </InputGroup>
        </div>
      </div>

      <div className="w-[calc(100%-1.50rem)] md:w-[calc(100%-1.50rem)] lg:w-[calc(100%-26.50rem)] mb-0 mt-[102px] pb-3 pt-5 md:pt-5 lg:pt-10 fixed z-20 bg-white/10 dark:bg-[#00000023] backdrop-blur-lg flex flex-col md:flex-col lg:flex-row items-center">
        <div className="order-1 md:order-1 lg:order-2 w-full md:w-full lg:w-6/12 flex items-center justify-center md:justify-center lg:justify-end text-brand-200 mb-6 md:mb-6 lg:mb-0">
          {!isMutation.loading ?
            <>
              <div className="hidden md:block">
                <Stack spacing={2}>
                  <Pagination
                    count={Math.ceil(!isMutation.loading && Number(totalFilter) / limit)}
                    page={currentPage}
                    onChange={handleChangePage}
                    variant="text"
                    color="primary"
                    shape="rounded"
                    size="large"
                    renderItem={(item) => (
                      <PaginationItem
                        slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                        {...item}
                      />
                    )}
                    className="bg-brand-200 dark:bg-brand-950"
                  />
                </Stack>
              </div>
              <div className="block md:hidden">
                <Stack spacing={2}>
                  <Pagination
                    count={Math.ceil(!isMutation.loading && Number(totalFilter) / limit)}
                    page={currentPage}
                    onChange={handleChangePage}
                    variant="text"
                    color="primary"
                    shape="rounded"
                    size="small"
                    renderItem={(item) => (
                      <PaginationItem
                        slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                        {...item}
                      />
                    )}
                    className="bg-brand-200 dark:bg-brand-950"
                  />
                </Stack>
              </div>
            </> : <Spinner />
          }
        </div>
        <div className="order-2 md:order-2 lg:order-1 w-full md:w-full lg:w-6/12">

          <Tooltip title="Click aquí para filtrar servicios expirados" arrow={true}>
            <div onClick={() => HandleChangeFilterPedidos("expired")} className={`flex inline-flex cursor-pointer items-center mb-4 ${pedidosByState === "expired" && "border-dashed border-2 border-lightPrimary dark:border-darkPrimary"} bg-white dark:bg-brand-900 shadow-xl shadow-neutral-200 dark:shadow-none text-red-500 p-2 md:p-3 rounded-lg`}>
              <div className="flex inline-flex items-center"><MdOutlineTimerOff className="mr-0 text-2xl md:text-4xl" /> <span className="hidden md:block"></span></div>
              <div className="-mt-12 -mr-4">
                <span class="relative flex h-6 w-6 md:w-9 md:h-9">
                  <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-500"></span>
                  <span style={{ fontFamily: 'Outfit, sans-serif' }} class="relative inline-flex rounded-full h-6 w-6 md:w-9 md:h-9 p-2 
                          bg-white dark:bg-brand-900 shadow-xl shadow-neutral-200 dark:shadow-none items-center justify-center text-brand-900 dark:text-brand-200 font-semibold text-base md:text-xl">
                    {!contador.loading ? contador.expired : <Spinner2 />}
                  </span>
                </span>
              </div>
            </div>
          </Tooltip>

          <Tooltip title="Click aquí para filtrar pedidos próximos a expirar" arrow={true}>
            <div onClick={() => HandleChangeFilterPedidos("proxim")} className={`flex inline-flex cursor-pointer items-center mb-4 ${pedidosByState === "proxim" && "border-dashed border-2 border-lightPrimary dark:border-darkPrimary"} bg-white dark:bg-brand-900 shadow-xl shadow-neutral-200 dark:shadow-none text-orange-400 p-2 md:p-3 rounded-lg ml-3 md:ml-5`}>
              <div className="flex inline-flex items-center"><MdTimer className="mr-0 text-2xl md:text-4xl" /> <span className="hidden md:block"></span></div>
              <div className="-mt-12 -mr-4">
                <span class="relative flex h-6 w-6 md:w-9 md:h-9">
                  <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-orange-400"></span>
                  <span style={{ fontFamily: 'Outfit, sans-serif' }} class="relative inline-flex rounded-full h-6 w-6 md:w-9 md:h-9 p-2
            bg-white dark:bg-brand-900 shadow-xl shadow-neutral-200 dark:shadow-none items-center justify-center text-brand-900 dark:text-brand-200 font-semibold text-base md:text-xl">
                    {!contador.loading ? contador.proxExpired : <Spinner2 />}
                  </span>
                </span>
              </div>
            </div>
          </Tooltip>

          <Tooltip title="Click aquí para filtrar pedidos pendientes" arrow={true}>
            <div onClick={() => HandleChangeFilterPedidos("pending")} className={`flex inline-flex cursor-pointer items-center mb-4 ${pedidosByState === "pending" && "border-dashed border-2 border-lightPrimary dark:border-darkPrimary"} bg-white dark:bg-brand-900 shadow-xl shadow-neutral-200 dark:shadow-none text-yellow-500 p-2 md:p-3 rounded-lg ml-3 md:ml-5`}>
              <div className="flex inline-flex items-center"><FaShippingFast className="mr-0 text-2xl md:text-4xl" /> <span className="hidden md:block"></span></div>
              <div className="-mt-12 -mr-4">
                <span class="relative flex h-6 w-6 md:w-9 md:h-9">
                  <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-yellow-500"></span>
                  <span style={{ fontFamily: 'Outfit, sans-serif' }} class="relative inline-flex rounded-full h-6 w-6 md:w-9 md:h-9 p-2 
            bg-white dark:bg-brand-900 shadow-xl shadow-neutral-200 dark:shadow-none items-center justify-center text-brand-900 dark:text-brand-200 font-semibold text-base md:text-xl">
                    {!contador.loading ? contador.pending : <Spinner2 />}
                  </span>
                </span>
              </div>
            </div>
          </Tooltip>

          <Tooltip title="Click aquí para filtrar pedidos bloqueados" arrow={true}>
            <div onClick={() => HandleChangeFilterPedidos("locked")} className={`flex inline-flex cursor-pointer items-center mb-4 ${pedidosByState === "locked" && "border-dashed border-2 border-lightPrimary dark:border-darkPrimary"} bg-white dark:bg-brand-900 shadow-xl shadow-neutral-200 dark:shadow-none text-red-700 p-2 md:p-3 rounded-lg ml-3 md:ml-5`}>
              <div className="flex inline-flex items-center"><IoLockClosed className="mr-0 text-2xl md:text-4xl" /> <span className="hidden md:block"></span></div>
              <div className="-mt-12 -mr-4">
                <span class="relative flex h-6 w-6 md:w-9 md:h-9">
                  <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-600"></span>
                  <span style={{ fontFamily: 'Outfit, sans-serif' }} class="relative inline-flex rounded-full h-6 w-6 md:w-9 md:h-9 p-2 
            bg-white dark:bg-brand-900 shadow-xl shadow-neutral-200 dark:shadow-none items-center justify-center text-brand-900 dark:text-brand-200 font-semibold text-base md:text-xl">
                    {!contador.loading ? contador.locked : <Spinner2 />}
                  </span>
                </span>
              </div>
            </div>
          </Tooltip>

          <Tooltip title="Click aquí para filtrar pedidos rechazados" arrow={true}>
            <div onClick={() => HandleChangeFilterPedidos("rejected")} className={`flex inline-flex cursor-pointer items-center mb-4 ${pedidosByState === "rejected" && "border-dashed border-2 border-lightPrimary dark:border-darkPrimary"} bg-white dark:bg-brand-900 shadow-xl shadow-neutral-200 dark:shadow-none text-red-700 p-2 md:p-3 rounded-lg ml-3 md:ml-5`}>
              <div className="flex inline-flex items-center"><IoBan className="mr-0 text-2xl md:text-4xl" /> <span className="hidden md:block"></span></div>
              <div className="-mt-12 -mr-4">
                <span class="relative flex h-6 w-6 md:w-9 md:h-9">
                  <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-600"></span>
                  <span style={{ fontFamily: 'Outfit, sans-serif' }} class="relative inline-flex rounded-full h-6 w-6 md:w-9 md:h-9 p-2 
            bg-white dark:bg-brand-900 shadow-xl shadow-neutral-200 dark:shadow-none items-center justify-center text-brand-900 dark:text-brand-200 font-semibold text-base md:text-xl">
                    {!contador.loading ? contador.rejected : <Spinner2 />}
                  </span>
                </span>
              </div>
            </div>
          </Tooltip>

          <Tooltip title="Click aquí para filtrar todos los pedidos" arrow={true}>
            <div onClick={() => HandleChangeFilterPedidos("")} className={`flex inline-flex cursor-pointer items-center mb-4 ${pedidosByState === "" && "border-dashed border-2 border-lightPrimary dark:border-darkPrimary"} bg-white dark:bg-brand-900 shadow-xl shadow-neutral-200 dark:shadow-none text-green-500 p-2 md:p-3 rounded-lg ml-3 md:ml-5`}>
              <div className="flex inline-flex items-center"><IoBagCheck className="mr-0 text-2xl md:text-4xl" /> <span className="hidden md:block"></span></div>
              <div className="-mt-12 -mr-4">
                <span class="relative flex h-6 w-6 md:w-9 md:h-9">
                  <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-500"></span>
                  <span style={{ fontFamily: 'Outfit, sans-serif' }} class="relative inline-flex rounded-full h-6 w-6 md:w-9 md:h-9 p-2 
            bg-white dark:bg-brand-900 shadow-xl shadow-neutral-200 dark:shadow-none items-center justify-center text-brand-900 dark:text-brand-200 font-semibold text-base md:text-xl">
                    {!contador.loading ? contador.total : <Spinner2 />}
                  </span>
                </span>
              </div>
            </div>
          </Tooltip>

        </div>
      </div>

      <div className='w-full'>
        <Modal
          open={completarPedidoConEmail.open}
          onClose={() => { setCompletarPedidoConEmail({ ...completarPedidoConEmail, open: false, correo: "", passwordcorreo: "", passwordcuenta: "" }) }}
          headerTitle={`Completar pedido`}
          size='base'
        >
          <div className="relative w-full">
            <div className="w-full md:w-12/12">
              <form className="w-full flex flex-wrap gap-4" onSubmit={onCompletarPedidoConEmail}>
                <div className="w-full md:w-12/12">
                  <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Correo del cliente</label>
                  <input autoFocus name="correo" className="custom-style-input"
                    autoComplete="off"
                    value={completarPedidoConEmail.correo}
                    onChange={(e) => { setCompletarPedidoConEmail({ ...completarPedidoConEmail, correo: e.target.value }) }}
                    type="text"
                  />
                </div>
                <div className="w-full relative">
                  <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Password del correo <span className="text-xs text-amber-600 dark:text-amber-400">(opcional)</span></label>
                  <input name="passwordcorreo" className="custom-style-input"
                    autoComplete="off"
                    value={completarPedidoConEmail.passwordcorreo}
                    onChange={(e) => { setCompletarPedidoConEmail({ ...completarPedidoConEmail, passwordcorreo: e.target.value }) }}
                    type={completarPedidoConEmail.verpasswordcorreo ? 'text' : 'password'}
                  />
                  <div class="absolute inset-y-4 mt-7 right-0 pr-3 flex items-center text-sm leading-5">
                    {<IconButton color='primary' onClick={() => setCompletarPedidoConEmail({ ...completarPedidoConEmail, verpasswordcorreo: !completarPedidoConEmail.verpasswordcorreo })}>
                      {completarPedidoConEmail.verpasswordcorreo ? <IoEyeOff className="text-brand-900 dark:text-brand-200" /> : <IoEye className="text-brand-900 dark:text-brand-200" />}
                    </IconButton>}
                  </div>
                </div>

                <div className="w-full relative">
                  <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Password de la cuenta</label>
                  <input name="passwordcuenta" className="custom-style-input"
                    autoComplete="off"
                    required
                    value={completarPedidoConEmail.passwordcuenta}
                    onChange={(e) => { setCompletarPedidoConEmail({ ...completarPedidoConEmail, passwordcuenta: e.target.value }) }}
                    type={completarPedidoConEmail.verpasswordcuenta ? 'text' : 'password'}
                  />
                  <div class="absolute inset-y-4 mt-7 right-0 pr-3 flex items-center text-sm leading-5">
                    {<IconButton color='primary' onClick={() => setCompletarPedidoConEmail({ ...completarPedidoConEmail, verpasswordcuenta: !completarPedidoConEmail.verpasswordcuenta })}>
                      {completarPedidoConEmail.verpasswordcuenta ? <IoEyeOff className="text-brand-900 dark:text-brand-200" /> : <IoEye className="text-brand-900 dark:text-brand-200" />}
                    </IconButton>}
                  </div>
                </div>
                <div className="flex m-auto w-full mt-5">
                  <Button
                    type="submit"
                    style={{ width: '100%', height: 65, marginLeft: 'auto', marginRight: 'auto' }}
                    size='large' variant="contained"
                    disabled={isMutation.uloading ? true : false}
                  >
                    {isMutation.uloading ? <Spinner /> : <FaRocket size={24} className="mr-2" />}
                    {isMutation.uloading ? 'Procesando...' : 'Completar pedido'}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </Modal>

        <Modal
          open={transferOrder.open}
          onClose={() => { SetTransferOrder({ ...transferOrder, open: false, userTarget: "", _id: "" }) }}
          headerTitle={`Transferir pedido`}
          size='base'
        >
          <div className="relative w-full">
            <div className="w-full md:w-12/12">

              <div class="flex items-center p-3 mb-3 text-sm rounded-lg bg-lightPrimary/10 dark:bg-darkPrimary/10 text-blue-600 dark:text-blue-500" role="alert">
                <svg class="flex-shrink-0 inline w-8 h-8 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                </svg>
                <span class="sr-only">Info</span>
                <div>
                  <span class="font-bold uppercase">Importante!</span> Ingresar el correo del usuario a quién vas a transferir este pedido. El usuario actual perderá todo acceso o control del pedido.
                </div>
              </div>

              <div className="w-full md:w-12/12">
                <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Ingresar correo</label>
                <input autoFocus required name="userTarget" className="custom-style-input"
                  autoComplete="off"
                  value={transferOrder.userTarget}
                  onChange={(e) => { SetTransferOrder({ ...transferOrder, userTarget: e.target.value }) }}
                  type="email"
                />
              </div>
              <div className="flex m-auto w-full mt-5">
                <Button
                  onClick={onSendTransferOrder}
                  style={{ width: '100%', height: 65, marginLeft: 'auto', marginRight: 'auto' }}
                  size='large' variant="contained"
                  disabled={isMutation.uloading ? true : false}
                >
                  {isMutation.uloading ? <Spinner /> : <IoSave size={24} className="mr-2" />}
                  {isMutation.uloading ? 'Actualizando...' : 'Transferir pedido'}
                </Button>
              </div>
            </div>
          </div>
        </Modal>


        <Modal
          open={reembolsarOrder.open}
          onClose={() => { SetReembolsarOrder({ ...reembolsarOrder, open: false, amount: "", _id: "" }) }}
          headerTitle={`Reembolsar pedido`}
          size='base'
        >
          <div className="relative w-full">
            <div className="w-full md:w-12/12">
              <div class="flex items-center p-3 mb-3 text-sm rounded-lg bg-lightPrimary/10 dark:bg-darkPrimary/10 text-blue-600 dark:text-blue-500" role="alert">
                <svg class="flex-shrink-0 inline w-8 h-8 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                </svg>
                <span class="sr-only">Info</span>
                <div>
                  <span class="font-bold uppercase">Importante!</span> El monto por defecto hace referencia al monto de los dias faltantes del servicio. Puedes dejarlo por defecto o ingresar un monto manualmente.
                </div>
              </div>

              <div class="flex items-center p-3 mb-3 text-sm rounded-lg bg-lightError/10 dark:bg-darkError/10 text-red-600 dark:text-red-500" role="alert">
                <svg class="flex-shrink-0 inline w-8 h-8 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                </svg>
                <span class="sr-only">Info</span>
                <div>
                  <span class="font-bold uppercase">Aviso de bloqueo de pedido!</span> El pedido quedará bloqueado en automático y se recomienda cambiar la contraseña de la cuenta.
                </div>
              </div>

              <div className="w-full md:w-12/12">
                <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Monto a reembolsar</label>
                <input autoFocus required name="amount" className="custom-style-input"
                  autoComplete="off"
                  value={reembolsarOrder.amount}
                  onChange={(e) => { SetReembolsarOrder({ ...reembolsarOrder, amount: e.target.value }) }}
                  type="number"
                  step={0.1}
                />
              </div>
              <div className="flex m-auto w-full mt-5">
                <Button
                  onClick={onSendRefundOrder}
                  style={{ width: '100%', height: 65, marginLeft: 'auto', marginRight: 'auto' }}
                  size='large' variant="contained"
                  disabled={isMutation.uloading ? true : false}
                >
                  {isMutation.uloading ? <Spinner /> : <IoSave size={24} className="mr-2" />}
                  {isMutation.uloading ? 'Reembolsando...' : 'Reembolsar pedido'}
                </Button>
              </div>
            </div>
          </div>
        </Modal>


        <Modal
          open={changeExpirationTime.open}
          onClose={() => { setChangeExpirationTime({ ...changeExpirationTime, open: false }) }}
          headerTitle={`Editar expiración del servicio`}
          size='base'
        >
          <div className="relative w-full">
            <div className="w-full md:w-12/12">
              <div className="w-full md:w-12/12">
                <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Nueva expiración del servicio</label>
                <input autoFocus name="newexpiration" className="custom-style-input"
                  autoComplete="off"
                  value={changeExpirationTime.newexpiration}
                  onChange={(e) => { setChangeExpirationTime({ ...changeExpirationTime, newexpiration: e.target.value }) }}
                  type="datetime-local"
                />
              </div>
              <div className="flex m-auto w-full mt-5">
                <Button
                  onClick={onSendChangeExpirationDate}
                  style={{ width: '100%', height: 65, marginLeft: 'auto', marginRight: 'auto' }}
                  size='large' variant="contained"
                  disabled={isMutation.uloading ? true : false}
                >
                  {isMutation.uloading ? <Spinner /> : <IoSave size={24} className="mr-2" />}
                  {isMutation.uloading ? 'Actualizando...' : 'Actualizar Servicio'}
                </Button>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          open={verdetalles.open}
          onClose={() => SetVerDetalles({
            ...verdetalles,
            open: false,
            categoryname: "",
            categorylogo: "",
            categoryrenovable: false,
            correo: "",
            passwordcuenta: "",
            data: []
          })}
          headerTitle={`Detalles del pedido`}
          size='base'
        >
          <div className="relative w-full">
            <label className="absolute -top-8 text-brand-950 dark:text-brand-50">{verdetalles.data.ventaId}</label>
            <div className="w-full mb-3 mt-7 md:mt-4 text-brand-900 dark:text-brand-200">
              <div className="relative flex inline-flex items-center">
                <LazyLoadImage
                  className="object-cover w-14 h-14 z-10 rounded-full flex inline-flex"
                  alt={`No image ${verdetalles.categoryname}`}
                  effect="opacity"
                  src={`/images/logos/${verdetalles.categorylogo}`}
                />
                <div className="grid self-center">
                  <div className="ml-2 text-base font-semibold flex inline-flex">{verdetalles.data.user?.name}</div>
                  <div className="ml-2 text-sm font-semibold">{verdetalles.categoryname}</div>
                  <div className="ml-2 text-sm"><span className="text-yellow-600 dark:text-yellow-400 truncate ...">
                    {verdetalles.data.state === "locked" ?
                      <span className="text-red-600"> Bloqueado</span>
                      :
                      verdetalles.correo
                    }
                  </span></div>
                </div>
              </div>
            </div>

            {
              verdetalles.data.user?.reseller && verdetalles.data.contactoInfo?.celular !== "" &&
              <div className="w-full flex inline-flex flex-wrap mt-5 text-brand-900 dark:text-brand-200 bg-brand-100 dark:bg-brand-900 shadow-xl shadow-brand-300 dark:shadow-none rounded-lg mb-5 pb-5">
                <label className="absolute -mt-4 text-lg ml-5">Información del cliente</label>
                <div className="grid grid-wrap w-full md:w-6/12 mt-4 pl-5 pr-5 pt-3 pb-3">
                  <LazyLoadImage
                    className="object-cover min-h-6 max-h-6 max-w-8 min-w-8 rounded-xs"
                    alt={`No image`}
                    effect="opacity"
                    src={`/images/flags/4x3/${verdetalles.data.contactoInfo?.countrycode}.svg`}
                  />
                  <div className="flex inline-flex items-center text-sm p-2 bg-white dark:bg-black rounded-lg mt-2">
                    <FaWhatsapp size={24} className="mr-2" />
                    <span>{verdetalles.data.contactoInfo?.celular}</span>
                  </div>
                  <div className="flex inline-flex items-center text-sm p-2 bg-white dark:bg-black rounded-lg mt-2">
                    <MdPersonPin size={24} className="mr-2" />
                    <span>{verdetalles.data.contactoInfo?.alias}</span>
                  </div>
                </div>
              </div>
            }

            <div className="w-full flex inline-flex flex-wrap mt-5 text-brand-900 dark:text-brand-200 bg-brand-100 dark:bg-brand-900 shadow-xl shadow-brand-300 dark:shadow-none rounded-lg mb-5 pb-5">
              <label className="absolute -mt-4 text-lg ml-5">Información general</label>
              <div className="grid grid-wrap w-full md:w-7/12 mt-4 pl-5 pr-5 pt-3 pb-3">

                <div className="flex inline-flex items-center text-sm p-2 bg-white dark:bg-black rounded-lg mt-2"><IoTime size={28} className="mr-2" />
                  {setUnixToTimeFormat("created_at", verdetalles.data.created_at)}</div>
                {moment(verdetalles.data.created_at).format("yyyy-MM-DDTHH:mm") !== moment(verdetalles.data.last_update).format("yyyy-MM-DDTHH:mm") && <div className="flex inline-flex items-center text-sm p-2 bg-white dark:bg-black rounded-lg mt-2">
                  <span className="text-green-500 flex items-center"><IoReload size={28} className="mr-2" />{setUnixToTimeFormat("renew", verdetalles.data.last_update)}</span></div>}
                <div className="flex inline-flex items-center text-sm p-2 bg-white dark:bg-black rounded-lg mt-2"><IoAlarm size={28} className={`${CalcularExpiracion(verdetalles.data.expirationdate) === 'Caducado' ? 'text-red-400' : 'text-green-500'} mr-2`} />
                  <span className={`${CalcularExpiracion(verdetalles.data.expirationdate) === 'Caducado' ? 'text-red-400' : 'text-green-500'}`}>{moment(verdetalles.data.expirationdate).format("dddd, DD [de] MMMM [de] yyyy")}</span></div>

                <div className="flex inline-flex items-center text-sm p-2 bg-white dark:bg-black rounded-lg mt-2"><IoTime size={28} className="mr-2" />
                  <span>{verdetalles.data.totalmonths < 2 ? verdetalles.data.totalmonths + ' mes' : verdetalles.data.totalmonths + ' meses'}</span></div>
                <div className="flex inline-flex items-center p-2 bg-white dark:bg-black rounded-lg mt-2">
                  {verdetalles.data.state === "complete" && <div className="flex inline-flex items-center text-green-500 text-sm">
                    <IoCheckbox size={28} className="mr-2" /> Completado</div>}
                  {verdetalles.data.state === "pending" && <div className="flex inline-flex items-center text-yellow-400 text-sm">
                    <MdAvTimer size={28} className="mr-2" />Pendiente</div>}
                  {verdetalles.data.state === "rejected" && <div className="flex inline-flex items-center text-red-400 text-sm">
                    <IoThumbsDownSharp size={28} className="mr-2" /> Rechazado</div>}
                  {verdetalles.data.state === "locked" && <div className="flex inline-flex items-center text-red-400 text-sm">
                    <IoLockClosed size={28} className="mr-2" /> Bloqueado</div>}
                </div>
                <div className="flex inline-flex items-center text-sm p-2 bg-white dark:bg-black rounded-lg mt-2"><IoDesktopSharp size={28} className="mr-2" />{verdetalles.data.type}</div>
                {
                  verdetalles.categoryrenovable &&
                  <div className="flex inline-flex items-center text-sm p-2 bg-white dark:bg-black rounded-lg mt-2"><MdAutorenew size={28} className="mr-2" />Autorenovación {verdetalles.data.autorenovable ? <span className="text-green-500 dark:text-green-400 ml-2">activado</span> : <span className="text-red-500 dark:text-red-400 ml-2">desactivado</span>}</div>
                }
              </div>
            </div>

            <div className="mt-5 bg-brand-100 dark:bg-brand-900 shadow-xl shadow-brand-300 dark:shadow-none p-5 rounded-lg">
              <label className="absolute -mt-9 text-lg text-brand-900 dark:text-brand-200 font-semibold">Información de cuenta</label>
              <div className="flex items-center w-full bg-white dark:bg-black p-3 rounded-lg">
                <div className="w-[35px] flex items-center"><MdMail size={28} className="text-yellow-500" /></div>
                <div className="w-[calc(100%-55px)] text-sm flex items-center">
                  <span className="text-brand-900 dark:text-brand-100 flex items-center mr-1 font-semibold">Correo :</span>
                  <div className="text-brand-800 dark:text-brand-300 w-[70%] truncate ...">
                    {verdetalles.data.state === "locked" ?
                      <span className="text-red-400"> Bloqueado</span>
                      :
                      verdetalles.correo}
                  </div>
                </div>
                <div className="w-[20px] flex items-center">
                  {
                    verdetalles.data.state === "complete" && <Tooltip title="copiar" arrow={true}>
                      <div>
                        <CopyToClipboard text={verdetalles.correo} onCopy={() => onCopy('Correo copiado')}>
                          <FaClipboard size={20} className="text-brand-900 dark:text-brand-200 cursor-pointer" />
                        </CopyToClipboard>
                      </div>
                    </Tooltip>
                  }
                </div>
              </div>
              <div className="relative flex items-center w-full bg-white dark:bg-black p-3 mt-3 rounded-lg">
                <div className="min-w-[35px] flex items-center"><IoFingerPrint size={28} className="mr-1 text-red-500" /></div>
                <div className="w-[calc(100%-55px)] text-sm flex items-center">
                  <span className="min-w-[86px] text-brand-900 dark:text-brand-100 flex items-center font-semibold">Contraseña : </span>
                  <div className="ml-1 text-brand-800 dark:text-brand-300 truncate ...">
                    {verdetalles.data.state === "locked" ?
                      <span className="text-red-400"> Bloqueado</span>
                      :
                      verdetalles.passwordcuenta}
                  </div>
                </div>
                <div className="min-w-[20px] flex items-center">
                  {
                    verdetalles.data.state === "complete" && <Tooltip title="copiar" arrow={true}>
                      <div>
                        <CopyToClipboard text={verdetalles.passwordcuenta} onCopy={() => onCopy('Contraseña copiada')}>
                          <FaClipboard size={20} className="text-brand-900 dark:text-brand-200 cursor-pointer" />
                        </CopyToClipboard>
                      </div>
                    </Tooltip>
                  }
                </div>
              </div>
            </div>

            {verdetalles.data.isprofileblocked &&
              <div className="w-full flex inline-flex flex-wrap mt-5 bg-brand-100 dark:bg-brand-900 shadow-xl shadow-brand-300 dark:shadow-none">
                <div className="w-full flex flex-wrap mt-5 rounded-lg p-5">
                  <label className="absolute -mt-9 text-lg text-brand-900 dark:text-brand-200 font-semibold">Información de perfiles</label>
                  {
                    verdetalles.data.state !== "locked" ? (
                      verdetalles.data.dataperfiles?.map((dp, index) => {
                        return (
                          <div key={index} className="w-full text-brand-900 dark:text-brand-200">
                            <div className="w-full mt-3">
                              <div className="grid grid-wrap w-full md:w-4/12">
                                <div className="flex inline-flex items-center">
                                  <div className="m-2 w-14 h-14 rounded-full bg-brand-700 dark:bg-brand-400 flex inline-flex items-center"><IoPerson className="w-10 h-10 z-30 flex inline-flex m-auto text-brand-300" /></div>
                                  <div className="grid self-center">
                                    <div className="ml-2 text-base font-semibold">{dp.name}</div>
                                    <div className="ml-2 text-sm flex inline-flex items-center bg-brand-200 dark:bg-brand-700 p-3"><IoFingerPrint size={24} /><span className="text-yellow-700 dark:text-yellow-500 font-semibold text-xl">{dp.password === "" ? 'Sin contraseña' : dp.password}</span></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })
                    ) : <span className="text-red-600 ml-5">Bloqueado</span>
                  }
                </div>
              </div>
            }

            <div className='flex items-center justify-center mt-10 mb-5'>
              <div className="bg-brand-300 dark:bg-brand-900 hover:bg-brand-200 dark:hover:bg-brand-900 rounded-full w-24 h-24 flex items-center justify-center">
                <Tooltip title="copiar" arrow={true}>
                  <div>
                    <CopyToClipboard text={infopedido} onCopy={() => onCopy('Información copiada')}>
                      <FaClipboard size={45} className="text-brand-900 dark:text-brand-200 cursor-pointer" />
                    </CopyToClipboard>
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          open={questionrejected.open}
          onClose={() => SetQuestionRejected({ ...questionrejected, open: false, _id: '', actiontype: '', message: '' })}
          headerTitle={`Rechazar pedido`}
          size='base'
        >
          <div className="relative w-full">
            <div className="w-full mb-5">
              <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Motivo por el cual rechazas este pedido (max: 20 chars)</label>
              <input autoFocus name="message" className="custom-style-input"
                autoComplete="off"
                maxLength={20}
                required
                value={questionrejected.message}
                onChange={e => SetQuestionRejected({ ...questionrejected, message: e.target.value })}
                type="text"
              />
            </div>
            <div className="w-full mt-2">
              <Button
                onClick={() => handleLockOrRejectedVenta(questionrejected._id, 'rejected')}
                style={{ width: '100%', height: 65, marginLeft: 'auto', marginRight: 'auto' }}
                size='large' variant="contained" color="error"
                disabled={isMutation.uloading ? true : false}
              >
                {isMutation.uloading ? <Spinner /> : <IoBan size={24} className="mr-2" />}
                {isMutation.uloading ? 'Actualizando...' : 'RECHAZAR PEDIDO'}
              </Button>
            </div>

          </div>
        </Modal>
      </div>
      <div className="relative w-full">
        {!isMutation.loading ? ventasByPage.length > 0 ?
          <div className="flex flex-wrap mt-[300px] md:mt-[325px] lg:mt-[280px]">
            {ventasByPage.map((venta, index) => {

              return (
                <div className={`p-4 relative w-full shadow-xl shadow-neutral-200 dark:shadow-none cursor-pointer md:w-12/12 bg-white dark:bg-brand-900 text-brand-900 dark:text-brand-200 flex flex-wrap mb-14 rounded-tr-lg rounded-br-lg rounded-bl-lg animation`} key={venta._id}>
                  <label style={{ filter: venta.suspended ? 'grayscale(100%)' : 'none' }} className={`absolute -top-10 left-0 p-2 flex items-center text-brand-800 dark:text-brand-200 text-base rounded-tl-lg rounded-tr-lg bg-white dark:bg-brand-900`}>
                    <span className="text-[12px] flex items-center">{venta.ventaId} {venta.autorenovable && <MdAutorenew className="ml-4 text-green-600 dark:text-green-500 text-2xl" />}</span>
                  </label>
                  <div style={{ filter: venta.suspended ? 'grayscale(100%)' : 'none' }} className="relative grid grid-wrap w-full md:w-full lg:w-3/12">
                    <div className="flex inline-flex items-center">
                      <LazyLoadImage
                        className="object-cover max-w-[45px] min-w-[45px] max-h-[45px] min-h-[45px] z-10 rounded-full flex inline-flex"
                        alt={`No image ${venta.subcategory.name}`}
                        effect="opacity"
                        src={`/images/logos/${venta.subcategory.logo}`}
                      />
                      <div className="grid gap-1 self-center">
                        <div className="ml-2 text-sm text-brand-900 dark:text-brand-200 font-bold">{venta.subcategory.name}</div>
                        <div className="max-w-[calc(100%)] min-w-[calc(100%)] ml-2 flex items-center text-xs">
                          <div className="min-w-[20px] max-w-[20px]"><MdAlternateEmail size={16} /></div>
                          <div className="truncate ...">{venta.user?.email}</div>
                          <span className="min-w-[35px] max-w-[35px]">
                            <Tooltip title="copiar" arrow={true}>
                              <div>
                                <CopyToClipboard text={venta.user.email} onCopy={() => onCopy('correo copiado')}>
                                  <FaClipboard size={20} className="text-brand-900 dark:text-brand-200 cursor-pointer min-w-[35px] max-w-[35px]" />
                                </CopyToClipboard>
                              </div>
                            </Tooltip>
                          </span>
                        </div>
                        <div className="ml-2 flex items-center text-xs truncate ..."><FaRegUser size={16} className='mr-[5px]' /> {venta.user?.name}</div>
                        <div className="ml-2 flex items-center text-xs mt-1 truncate ..."><FaRegCalendarPlus size={16} className='mr-[5px]' />{setUnixToTimeFormat('created_at', venta.created_at)}</div>
                      </div>
                    </div>
                  </div>

                  <div style={{ filter: venta.suspended ? 'grayscale(100%)' : 'none' }} className="relative md:flex grid grid-cols-2 md:grid-cols-1 gap-3 items-center w-full md:w-full lg:w-8/12 mt-5 md:mt-4 lg:mt-0">

                    <div className="w-full md:w-3/12 grid">
                      <div className="flex items-center justify-center uppercase text-sm">Costo Total</div>
                      <div className="flex items-center justify-center">
                        <div className="flex inline-flex items-center">
                          {
                            venta.user?.reseller ?
                              <div class="text-center text-amber-950 dark:text-amber-50 bg-gradient-to-r from-amber-400 dark:from-amber-500 rounded-l-lg flex items-center p-2">
                                <FaCoins size={24} className="mr-2" />{currencyFormat(venta.totalamount)}
                              </div>
                              :
                              <div class="text-center text-green-950 dark:text-green-50 bg-gradient-to-r from-green-400 dark:from-green-500 rounded-l-lg flex items-center p-2">
                                <FaUserTag size={24} className="mr-2" />{currencyFormat(venta.totalamount)}
                              </div>
                          }
                        </div>
                      </div>
                    </div>

                    <div className="w-full md:w-3/12 grid">
                      <div className="flex items-center justify-center uppercase text-sm">Expiración</div>
                      <div className="flex items-center justify-center">
                        <div className="flex inline-flex items-center">
                          {venta.expirationdate === null ?
                            <div class="text-center text-yellow-950 dark:text-yellow-50 bg-gradient-to-r from-yellow-400 dark:from-yellow-500 rounded-l-lg flex items-center p-2"><IoAlarm size={24} className="mr-2" />Expiración pendiente</div>
                            :
                            <span>{(CalcularExpiracion(venta.expirationdate) === "Caducado" && venta.state !== "locked") ?
                              <div class="text-center text-red-950 dark:text-red-50 bg-gradient-to-r from-red-400 dark:from-red-500 rounded-l-lg flex items-center p-2"><IoAlarm size={24} className="mr-2" />{CalcularExpiracion(venta.expirationdate)}</div>
                              :
                              <div>{(CalcularExpiracion(venta.expirationdate).substring(0, 2).trim().length === 1 && Number(CalcularExpiracion(venta.expirationdate).substring(0, 1)) >= 0 &&
                                Number(CalcularExpiracion(venta.expirationdate).substring(0, 1)) < 3) ?
                                <div class="text-center text-orange-950 dark:text-orange-50 bg-gradient-to-r from-orange-400 dark:from-orange-500 rounded-l-lg flex items-center p-2"><IoAlarm size={24} className="mr-2" />{CalcularExpiracion(venta.expirationdate)}</div>
                                :
                                (venta.state === 'rejected' || venta.state === 'locked') ?
                                  <div class="text-center text-red-950 dark:text-red-50 bg-gradient-to-r from-red-400 dark:from-red-500 rounded-l-lg flex items-center p-2"><IoAlarm size={24} className="mr-2" />{CalcularExpiracion(venta.expirationdate)}</div>
                                  :
                                  <div class="text-center text-green-950 dark:text-green-50 bg-gradient-to-r from-green-400 dark:from-green-500 rounded-l-lg flex items-center p-2"><IoAlarm size={24} className="mr-2" />{CalcularExpiracion(venta.expirationdate)}</div>
                              }</div>
                            }</span>
                          }
                        </div>
                      </div>
                    </div>

                    <div className="w-full md:w-3/12 grid">
                      <div className="flex items-center justify-center uppercase text-sm">Tipo servicio</div>
                      <div className="flex items-center justify-center">
                        <div className="flex inline-flex items-center">
                          {venta.type === '1 pantalla' ?
                            <div class="text-center text-indigo-950 dark:text-indigo-50 bg-gradient-to-r from-indigo-400 dark:from-indigo-500 rounded-l-lg flex items-center p-2"><FaMobileScreen size={24} className="mr-2" />{venta.type}</div>
                            :
                            <div class="text-center text-amber-950 dark:text-amber-50 bg-gradient-to-r from-amber-400 dark:from-amber-500 rounded-l-lg flex items-center p-2"><FaScreenpal size={24} className="mr-2" />{venta.type}</div>
                          }
                        </div>
                      </div>
                    </div>

                    <div className="w-full md:w-3/12 grid">
                      <div className="flex items-center justify-center uppercase text-sm">Estado</div>
                      <div className="flex items-center justify-center">
                        {venta.state === "complete" && <div class="text-center text-green-950 dark:text-green-50 bg-gradient-to-r from-green-400 dark:from-green-500 rounded-l-lg flex items-center p-2"><FaSquareCheck size={24} className="mr-2" />Completado</div>}
                        {venta.state === "pending" && <div class="text-center text-yellow-950 dark:text-yellow-50 bg-gradient-to-r from-yellow-400 dark:from-yellow-500 rounded-l-lg flex items-center p-2"><FaShippingFast size={24} className="mr-2" />Pendiente</div>}
                        {venta.state === "rejected" && <div class="text-center text-red-950 dark:text-red-50 bg-gradient-to-r from-red-400 dark:from-red-500 rounded-l-lg flex items-center p-2"><IoBan size={24} className="mr-2" />Rechazado</div>}
                        {venta.state === "locked" && <div class="text-center text-red-950 dark:text-red-50 bg-gradient-to-r from-red-400 dark:from-red-500 rounded-l-lg flex items-center p-2"><IoLockClosed size={24} className="mr-2" />Bloqueado</div>}
                      </div>

                      {venta.observations !== "" &&
                        <span className='text-xs text-center'>
                          {venta.observations}
                        </span>
                      }

                    </div>

                  </div>

                  <div className="flex items-center gap-2 justify-end w-full md:w-full lg:w-1/12 mt-5 md:mt-5 lg:mt-0">
                    <Button
                      id="basic-button"
                      aria-controls={Boolean(anchorEls[index]) ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={Boolean(anchorEls[index]) ? 'true' : undefined}
                      onClick={(event) => handleClick(event, index)}
                      color="primary"
                      variant="contained"
                      startIcon={<IoMenu />}
                    >
                      Opciones
                    </Button>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEls[index]}
                      open={Boolean(anchorEls[index])}
                      onClose={() => handleClose(index)}
                      onClick={() => handleClose(index)}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >

                      <MenuItem onClick={() => handleSendEmailWhitInformationService(venta._id, venta.subcategory.policy)} disabled={(venta.state === 'complete' && !isMutationNotification.loading) ? false : true} className="flex items-center w-full h-12 text-base transition-colors ease-in-out duration-500 hover:bg-lightPrimary/20 dark:hover:darkPrimary/20 active:bg-lightPrimary/40 dark:active:darkPrimary/40">
                        {
                          isMutationNotification.loading ?
                            <SpinnerButtom />
                            :
                            <div className='w-10 h-10 flex items-center justify-center rounded-lg bg-red-600 dark:bg-red-500'>
                              <FaEnvelope className="w-5 h-5 text-brand-100 dark:text-brand-50" />
                            </div>
                        }
                        <div className='ml-2'>enviar información</div>
                      </MenuItem>

                      <MenuItem onClick={() => handleOpenDetalles(venta)} disabled={(venta.state === "rejected" || venta.state === "pending") ? true : false} className="flex items-center w-full h-12 text-base transition-colors ease-in-out duration-500 hover:bg-lightPrimary/20 dark:hover:darkPrimary/20 active:bg-lightPrimary/40 dark:active:darkPrimary/40">
                        <div className='w-10 h-10 flex items-center justify-center rounded-lg bg-blue-600 dark:bg-blue-500'>
                          <FaEye className="w-5 h-5 text-brand-100 dark:text-brand-50" />
                        </div>
                        <div className='ml-2'>ver detalles</div>
                      </MenuItem>


                      {
                        (venta.state === "pending" && venta.state !== "rejected") ?
                          <MenuItem onClick={() => HandleCompletarPedido(
                            venta._id,
                            venta.category._id,
                            venta.subcategory._id,
                            venta.user._id,
                            venta.subcategory.maximoperfiles,
                            venta.type,
                            venta.totalmonths,
                            venta.totalamount,
                            venta.discountuser,
                            venta.subcategory.name,
                            venta.isprofileblocked,
                            venta.contactoInfo,
                            venta.subcategory.policy,
                            venta.subcategory.requiredemail
                          )} disabled={(venta.state === "rejected" || isMutation.uloading) ? true : false} className="flex items-center w-full h-12 text-base transition-colors ease-in-out duration-500 hover:bg-lightPrimary/20 dark:hover:darkPrimary/20 active:bg-lightPrimary/40 dark:active:darkPrimary/40">
                            {
                              isMutation.uloading ?
                                <SpinnerButtom />
                                :
                                <div className='w-10 h-10 flex items-center justify-center rounded-lg bg-green-600 dark:bg-green-500'>
                                  <FaRocket className="w-5 h-5 text-brand-100 dark:text-brand-50" />
                                </div>
                            }
                            <div className='ml-2'>completar pedido</div>
                          </MenuItem>
                          :
                          <MenuItem onClick={() => openHandleChangeExpireTime(venta.expirationdate, venta._id)} disabled={(venta.state === "rejected" || venta.state === "pending" || venta.state === "locked") ? true : false} className="flex items-center w-full h-12 text-base transition-colors ease-in-out duration-500 hover:bg-lightPrimary/20 dark:hover:darkPrimary/20 active:bg-lightPrimary/40 dark:active:darkPrimary/40">
                            <div className='w-10 h-10 flex items-center justify-center rounded-lg bg-yellow-600 dark:bg-yellow-500'>
                              <FaPencilAlt className="w-5 h-5 text-brand-100 dark:text-brand-50" />
                            </div>
                            <div className='ml-2'>editar</div>
                          </MenuItem>
                      }
                      {
                        venta.state === 'pending' ?
                          <MenuItem onClick={() => SetQuestionRejected({ ...questionrejected, open: true, _id: venta._id, actiontype: 'rejected' })} disabled={(CalcularExpiracion(venta.expirationdate) !== "Caducado" || (venta.state === "complete" && CalcularExpiracion(venta.expirationdate) !== "Caducado")) ? true : false} className="flex items-center w-full h-12 text-base transition-colors ease-in-out duration-500 hover:bg-lightPrimary/20 dark:hover:darkPrimary/20 active:bg-lightPrimary/40 dark:active:darkPrimary/40">
                            <div className='w-10 h-10 flex items-center justify-center rounded-lg bg-red-600 dark:bg-red-500'>
                              <FaBan className="w-5 h-5 text-brand-100 dark:text-brand-50" />
                            </div>
                            <div className='ml-2'>rechazar pedido</div>
                          </MenuItem>
                          :
                          (venta.state === 'locked') ?
                            <MenuItem disabled={(CalcularExpiracion(venta.expirationdate) !== "Caducado" || (venta.state === "complete" && CalcularExpiracion(venta.expirationdate) !== "Caducado")) ? true : false} className="flex items-center w-full h-12 text-base transition-colors ease-in-out duration-500 hover:bg-lightPrimary/20 dark:hover:darkPrimary/20 active:bg-lightPrimary/40 dark:active:darkPrimary/40">
                              <div className='w-10 h-10 flex items-center justify-center rounded-lg bg-green-600 dark:bg-green-500'>
                                <FaLockOpen className="w-5 h-5 text-brand-100 dark:text-brand-50" />
                              </div>
                              <div className='ml-2'>desbloquear</div>
                            </MenuItem>
                            :
                            <MenuItem onClick={() => handleLockOrRejectedVenta(venta._id, 'locked', venta.state, CalcularExpiracion(venta.expirationdate))} disabled={(venta.state === 'rejected' || venta.state === 'pending' || isMutation.uloading) ? true : false} className="flex items-center w-full h-12 text-base transition-colors ease-in-out duration-500 hover:bg-lightPrimary/20 dark:hover:darkPrimary/20 active:bg-lightPrimary/40 dark:active:darkPrimary/40">
                              {
                                isMutation.uloading ?
                                  <SpinnerButtom />
                                  :
                                  <div className='w-10 h-10 flex items-center justify-center rounded-lg bg-red-600 dark:bg-red-500'>
                                    <FaLock className="w-5 h-5 text-brand-100 dark:text-brand-50" />
                                  </div>
                              }
                              <div className='ml-2'>bloquear</div>
                            </MenuItem>
                      }
                      {
                        venta.suspended ?
                          <MenuItem onClick={() => handleSuspended(venta)} disabled={(venta.state !== "complete" || isMutation.uloading) ? true : false} className="flex items-center w-full h-12 text-base transition-colors ease-in-out duration-500 hover:bg-lightPrimary/20 dark:hover:darkPrimary/20 active:bg-lightPrimary/40 dark:active:darkPrimary/40">
                            {
                              isMutation.uloading ?
                                <SpinnerButtom />
                                :
                                <div className='w-10 h-10 flex items-center justify-center rounded-lg bg-green-600 dark:bg-green-500'>
                                  <FaUserCheck className="w-5 h-5 text-brand-100 dark:text-brand-50" />
                                </div>
                            }
                            <div className='ml-2'>reanudar</div>
                          </MenuItem>
                          :
                          <MenuItem onClick={() => handleSuspended(venta)} disabled={(venta.state !== "complete" || isMutation.uloading) ? true : false} className="flex items-center w-full h-12 text-base transition-colors ease-in-out duration-500 hover:bg-lightPrimary/20 dark:hover:darkPrimary/20 active:bg-lightPrimary/40 dark:active:darkPrimary/40">
                            {
                              isMutation.uloading ?
                                <SpinnerButtom />
                                :
                                <div className='w-10 h-10 flex items-center justify-center rounded-lg bg-orange-600 dark:bg-orange-500'>
                                  <FaUserClock className="w-5 h-5 text-brand-100 dark:text-brand-50" />
                                </div>
                            }
                            <div className='ml-2'>suspender</div>
                          </MenuItem>
                      }

                      <MenuItem onClick={() => tranferirPedido(venta._id)} disabled={venta.state !== "complete" ? true : false} className="flex items-center w-full h-12 text-base transition-colors ease-in-out duration-500 hover:bg-lightPrimary/20 dark:hover:darkPrimary/20 active:bg-lightPrimary/40 dark:active:darkPrimary/40">
                        <div className='w-10 h-10 flex items-center justify-center rounded-lg bg-indigo-600 dark:bg-indigo-500'>
                          <FaUserGroup className="w-5 h-5 text-brand-100 dark:text-brand-50" />
                        </div>
                        <div className='ml-2'>transferir pedido</div>
                      </MenuItem>

                      <MenuItem onClick={() => refundOrder(venta._id, venta.totalmonths, venta.totalamount, venta.created_at)} disabled={venta.state !== "complete" ? true : false} className="flex items-center w-full h-12 text-base transition-colors ease-in-out duration-500 hover:bg-lightPrimary/20 dark:hover:darkPrimary/20 active:bg-lightPrimary/40 dark:active:darkPrimary/40">
                        <div className='w-10 h-10 flex items-center justify-center rounded-lg bg-green-600 dark:bg-green-500'>
                          <FaHandHoldingDollar className="w-5 h-5 text-brand-100 dark:text-brand-50" />
                        </div>
                        <div className='ml-2'>reembolsar pedido</div>
                      </MenuItem>

                      <MenuItem onClick={() => deleteVenta(venta._id)} disabled={(venta.state !== 'locked' && venta.state !== 'rejected') ? true : false} className="flex items-center w-full h-12 text-base transition-colors ease-in-out duration-500 hover:bg-lightPrimary/20 dark:hover:darkPrimary/20 active:bg-lightPrimary/40 dark:active:darkPrimary/40">
                        <div className='w-10 h-10 flex items-center justify-center rounded-lg bg-red-600 dark:bg-red-500'>
                          <FaTrashAlt className="w-5 h-5 text-brand-100 dark:text-brand-50" />
                        </div>
                        <div className='ml-2'>eliminar</div>
                      </MenuItem>
                    </Menu>

                  </div>
                </div>
              )
            })}
          </div> : <div className="alert-danger font-semibold mt-[300px] md:mt-[325px] lg:mt-[280px]">Sin resultados en la búsqueda! o no existen pedidos registradas</div> : <div className="mt-[300px] md:mt-[325px] lg:mt-[280px]"><SpinnerData>Cargando pedidos, por favor espere...</SpinnerData></div>}
      </div>
    </Wrapper>
  );
};

export default StreamingOrders;
